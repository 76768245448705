import {useContext, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {AuthContext} from "../contexts/AuthContext";
import {useSnackbar} from 'notistack';
import {DetailPageContext} from "./detail-page-hoc/context";
import {detailPageActionTypes} from "./detail-page-hoc/constants";

const StatusUpdateButton = function ({process, label, color, api}) {
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(DetailPageContext);
    const statusUpdate = () => {
        setLoading(true);
        api.updateStatus({authorization}, state.data.id, {process}).then(data => {
            dispatch({type: detailPageActionTypes.UPDATE_DATA, data});
            setLoading(false);
        }).catch(err => {
            if (err?.response?.status === 400) {
                enqueueSnackbar('Hatalı işlem', {
                    variant: "error"
                });
            }
            setLoading(false);
        });
    }
    return (
        <LoadingButton
            variant="contained"
            color={color || 'primary'}
            sx={{ml: 1, textTransform: 'none', displayPrint: 'none'}}
            onClick={() => statusUpdate()}
            loading={loading}
        >{label}
        </LoadingButton>
    )
}

export default StatusUpdateButton;
