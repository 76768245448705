import Box from "@mui/material/Box";
import BackButton from "../BackButton";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import Typography from "@mui/material/Typography";
import StatusChip from "./StatusChip";
import CreatePurchaseItemButton from "./CreatePurchaseItemButton";
import StatusUpdateButton from "../StatusUpdateButton";
import {statusTranslate} from "../../helpers/purchase/status";
import PurchaseApi from '../../api/purchase'

const DetailActionButtons = function () {
    const {state} = useContext(DetailPageContext);
    const status = statusTranslate[state.data.status]

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <BackButton/>
            <Typography variant="h4" fontWeight="light" marginLeft={1}>
                # {state.data.companyPurchaseNumber}
            </Typography>
            <StatusChip status={state.data.status} sx={{marginLeft: 1}}/>
            {[statusTranslate.DEMAND.enum, statusTranslate.ACTIVE.enum].includes(status.enum) && (
                <CreatePurchaseItemButton sx={{marginLeft: 'auto'}}/>
            )}
            {status.statusUpdateButtons && status.statusUpdateButtons.map(
                ({label, process, color}) => <StatusUpdateButton
                    key={process} label={label} process={process} color={color} api={PurchaseApi}/>
            )}
        </Box>
    )
}

export default DetailActionButtons;
