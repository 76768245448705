import {useState} from "react";
import Button from "@mui/material/Button";
import PurchaseFormDialog from "./PurchaseFormDialog";
import {hasPermission} from "../../utils/permissions";

const CreatePurchaseButton = function ({sx = {}, suppliers}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >Talep Oluştur</Button>
            <PurchaseFormDialog
                title="Talep oluştur"
                open={open}
                handleClose={() => setOpen(false)}
                suppliers={suppliers}
            />
        </>
    )
}

export default hasPermission(CreatePurchaseButton, {permissions: ['add_purchase']});
