class SessionManager {
    constructor() {
        this._key = 'session';
        this._session = null;
    }

    get session() {
        this._session = JSON.parse(localStorage.getItem(this._key)) || {};
        return this._session;
    }

    set session(value) {
        localStorage.setItem(this._key, JSON.stringify(value));
    }

    getSessionData(key) {
        return this.session[key];
    }

    setSessionData(key, data) {
        this.session = {
            ...this.session,
            [key]: data
        }
    }

    endSession() {
        this.session = {}
    }
}

export default new SessionManager();
