import DataTable from "../components/purchase/DataTable";
import ListActionButtons from "../components/purchase/ListActionButtons";
import ListPageHOC from "../components/list-page-hoc";

const Purchases = function () {
    return (
        <ListPageHOC
            storageKey={'purchase-page'}
            sortModel={[{field: "createdAt", sort: "desc"}]}
            filters={{status: []}}
        >
            <ListActionButtons/>
            <DataTable />
        </ListPageHOC>
    )
}

export default Purchases;
