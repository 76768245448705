export const statusTranslate = {
    DEMAND: {
        chipLabel: 'Talep',
        statusUpdateButtons: [
            {
                label: 'Onayla',
                process: 'accepted',
            },
            {
                label: 'Reddedildi',
                process: 'declined',
                color: 'warning',
            }
        ],
        status: 'Talep oluşturuldu',
        enum: 'DEMAND'
    },
    DECLINED: {
        chipLabel: 'Reddedildi',
        status: 'Satın alma reddedildi',
        enum: 'DECLINED'
    },
    ACTIVE: {
        chipLabel: 'Açık',
        statusUpdateButtonLabel: '',
        statusUpdateButtons: [
            {
                label: 'Satın Alma Tamamla',
                process: 'completed',
            },
            {
                label: 'İade Et',
                process: 'returned',
                color: 'warning',
            },
            {
                label: 'İptal Et',
                process: 'canceled',
                color: 'error',
            },
        ],
        status: 'Açık',
        enum: 'ACTIVE'
    },
    CANCELED: {
        chipLabel: 'İptal',
        status: 'İptal edildi',
        enum: 'CANCELED'
    },
    RETURNED: {
        chipLabel: 'İade',
        status: 'İade edildi',
        enum: 'RETURNED'
    },
    COMPLETED: {
        chipLabel: 'Tamamlandı',
        status: 'Satın alma kapandı',
        enum: 'COMPLETED'
    },

}
