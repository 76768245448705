import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import PriceTextFormat from "../PriceTextFormat";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {transformDateToLocalString} from "../../utils/transform";

const PurchaseItemTable = function () {
    const {state} = useContext(DetailPageContext);

    return (
        <>
            <Typography variant="h6">Ürünler</Typography>
            <Divider/>
            {state.data.items.length === 0 && (
                <Typography variant="body1">Henüz ürün eklenmemiş.</Typography>
            )}
            <Grid container spacing={1}>
                {
                    state.data.items.map(item => {
                        return (
                            <Grid key={item.id} item xs={12} lg={6} height="max-content">
                                <TableContainer component={Paper}>
                                    <Table size="medium">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Ürün Malzeme Tanımı / Tarifi</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ebatlar</TableCell>
                                                <TableCell>{item.description}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Miktar</TableCell>
                                                <TableCell>{item.quantity}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Birim</TableCell>
                                                <TableCell>{item.unit}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Birim Fiyat</TableCell>
                                                <TableCell>
                                                    <PriceTextFormat
                                                        prefix={item.currency}
                                                        value={item.unitPrice}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Teslim Tarihi</TableCell>
                                                <TableCell>{item.deliveryDate ? transformDateToLocalString(item.deliveryDate) : '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default PurchaseItemTable;