import {useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Formik} from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import PurchaseItemApi from "../../api/purchase-item";
import {AuthContext} from "../../contexts/AuthContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {currencies} from "../../utils/currency";
import SelectField from "../forms/SelectField";
import PriceFieldFormat from "../PriceFieldFormat";
import {DetailPageContext} from "../detail-page-hoc/context";
import {detailPageActionTypes} from "../detail-page-hoc/constants";
import {units} from "../../utils/unit";
import DatePicker from "../forms/DatePicker";
import format from "date-fns/format";


const PurchaseItemFormDialog = function (
    {
        title,
        open,
        handleClose,
        initialValues = {
            name: '',
            description: '',
            unit: '',
            quantity: '',
            unitPrice: '',
            currency: '',
            deliveryDate: ''
        },
    }
) {
    const authorization = useContext(AuthContext);
    const {state, dispatch} = useContext(DetailPageContext);
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                // actions.setSubmitting(false);
                setLoading(true);
                const payload = {
                    purchase: state.data.id,
                    ...values,
                }
                if (typeof (values.deliveryDate) === 'object') {
                    payload.deliveryDate = format(values.deliveryDate, 'yyyy-MM-dd');
                }
                Object.entries(payload).forEach(([key, value]) => {
                    if (!value) delete payload[key]
                });
                PurchaseItemApi.save({authorization}, payload).then(response => {
                    setLoading(false);
                    dispatch({type: detailPageActionTypes.UPDATE_VERSION})
                    handleClose();
                });
            }}
        >
            {formik => (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <Stack sx={{py: 1}} spacing={1}>
                            <TextField
                                name="name"
                                label="Ürün Malzeme Tanımı / Tarifi"
                                size="small"
                            />
                            <TextField
                                name="description"
                                label="Ebatlar"
                                size="small"
                            />
                            <TextField
                                name="quantity"
                                label="Miktar"
                                inputMode="numeric"
                                size="small"
                            />
                            <DatePicker name="deliveryDate" label="Teslim Tarihi"/>
                            <Stack direction="row" spacing={1}>
                                <SelectField
                                    name="unit"
                                    label="Birim"
                                    values={units}
                                    nullable={false}
                                    valueField="key"
                                    size="small"
                                />
                                <TextField
                                    name="unitPrice"
                                    label="Birim Fiyat"
                                    inputMode="decimal"
                                    InputProps={{
                                        inputComponent: PriceFieldFormat
                                    }}
                                    size="small"
                                />
                                <SelectField
                                    name="currency"
                                    label="Para Birimi"
                                    values={currencies}
                                    nullable={false}
                                    valueField="key"
                                    size="small"
                                />
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loading}
                            sx={{textTransform: 'none'}}
                            onClick={() => formik.submitForm()}
                        >Kaydet</Button>
                        <Button
                            disabled={loading}
                            sx={{textTransform: 'none'}}
                            color="error"
                            onClick={handleClose}
                        >İptal</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Zorunlu bilgi'),
    description: yup
        .string(),
    quantity: yup
        .number()
        .required('Miktar giriniz')
        .moreThan(0, 'Miktar sıfırdan büyük olmalı'),
    unit: yup
        .string()
        .oneOf(units.map(unit => unit.value)),
    unitPrice: yup
        .number()
        .min(0, 'Birim fiyat negatif olamaz'),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
    deliveryDate: yup
        .date(),
});

export default PurchaseItemFormDialog;
