import SignIn from './SignIn';
import Purchases from './Purchases'
import PurchaseDetail from './PurchaseDetail'
import Orders from "./Orders";
import OrderDetail from "./OrderDetail";
import WorkOrders from "./WorkOrders";
import WorkOrderDetail from "./WorkOrderDetail";
import WorkOrderProcesses from "./WorkOrderProcesses";
import InvoiceOrders from "./InvoiceOrders";
import InvoiceOrderDetail from "./InvoiceOrderDetail";
import Customers from "./Customers";
import CustomerDetail from "./CustomerDetail";
import Actions from "./Actions";
import ActionDetail from "./ActionDetail";
import OperationTimeTracking from "./OperationTimeTracking";
import PriceList from "./PriceList";
import Formulas from "./Formulas";
import ProductionAnalysis from "./ProductionAnalysis";
import FinancialAnalysis from './FinancialAnalysis';
import Unauthorized from "./Unauthorized";
import ProductPool from "./ProductPool";
import ChecklistIcon from "@mui/icons-material/Checklist";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ContactsIcon from "@mui/icons-material/Contacts";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import MoneySharpIcon from "@mui/icons-material/MoneySharp";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RefreshAuth from "./RefreshAuth";

export const pages = [
    {
        label: 'İş emirleri',
        icon: EngineeringIcon,
        pathname: '/is-emirleri',
        page: WorkOrders,
        isDetailPage: false,
        permissions: [
            'view_workorder',
            'can_list_customers_in_dropdown',
            'view_product',
        ]
    },
    {
        label: 'İş emirleri',
        icon: EngineeringIcon,
        pathname: '/is-emirleri/:id',
        page: WorkOrderDetail,
        isDetailPage: true,
        permissions: [
            'retrieve_workorder',
        ]
    },
    {
        label: 'Proses Takip',
        icon: ManageSearchIcon,
        pathname: '/proses-takip',
        page: WorkOrderProcesses,
        isDetailPage: false,
        permissions: [
            'view_workorder',
            'can_list_customers_in_dropdown',
            'view_product',
            'view_processes',
        ]
    },
    {
        label: 'Fatura Emirleri',
        icon: ReceiptIcon,
        pathname: '/fatura-emirleri',
        page: InvoiceOrders,
        isDetailPage: false,
        permissions: [
            'view_invoiceorder',
        ]
    },
    {
        label: 'Fatura Emirleri',
        icon: ReceiptIcon,
        pathname: '/fatura-emirleri/:id',
        page: InvoiceOrderDetail,
        isDetailPage: true,
        permissions: [
            'retrieve_invoice',
        ]
    },
    {
        label: 'Siparişler',
        icon: ChecklistIcon,
        pathname: '/siparisler',
        page: Orders,
        isDetailPage: false,
        permissions: [
            'view_order',
            'can_list_customers_in_dropdown',
        ]
    },
    {
        label: 'Sipariş Detay',
        icon: ChecklistIcon,
        pathname: '/siparisler/:id',
        page: OrderDetail,
        isDetailPage: true,
        permissions: [
            'view_order',
        ]
    },
    {
        label: 'Müşteriler',
        icon: ContactsIcon,
        pathname: '/musteriler',
        page: Customers,
        isDetailPage: false,
        permissions: [
            'view_customer',
        ]
    },
    {
        label: 'Müşteriler',
        icon: ContactsIcon,
        pathname: '/musteriler/:id',
        page: CustomerDetail,
        isDetailPage: true,
        permissions: [
            'retrieve_customer',
        ]
    },
    {
        label: 'Aksiyonlar',
        icon: AssignmentIcon,
        pathname: '/aksiyonlar',
        page: Actions,
        isDetailPage: false,
        permissions: [
            'view_action',
        ]
    },
    {
        label: 'Aksiyonlar',
        icon: AssignmentIcon,
        pathname: '/aksiyonlar/:id',
        page: ActionDetail,
        isDetailPage: true,
        permissions: [
            'retrieve_action',
        ]
    },
    {
        label: 'Operasyon Zaman Takibi',
        icon: TimerOutlinedIcon,
        pathname: '/operasyon-zaman-takibi',
        page: OperationTimeTracking,
        isDetailPage: false,
        permissions: [
            'view_operationtimetracking',
            'view_equipment',
            'view_user',
        ]
    },
    {
        label: 'Fiyat Listeleri',
        icon: MoneySharpIcon,
        pathname: '/fiyat-listeleri',
        page: PriceList,
        isDetailPage: false,
        permissions: [
            'view_customerbroochsharpeningprice',
            'view_customerbroachingprice',
        ]
    },
    {
        label: 'Ürün Havuzu',
        icon: SnippetFolderOutlinedIcon,
        pathname: '/urun-havuzu',
        page: ProductPool,
        isDetailPage: false,
        permissions: [
            'view_productpool',
        ]
    },
    {
        label: 'Formüller',
        icon: CalculateRoundedIcon,
        pathname: '/formuller',
        page: Formulas,
        isDetailPage: false,
    },
    {
        label: 'Üretim Analizleri',
        icon: QueryStatsRoundedIcon,
        pathname: '/uretim-analizleri',
        page: ProductionAnalysis,
        isDetailPage: false,
        permissions: [
            'view_analysis',
        ]
    },
    {
        label: 'Finans Analizleri',
        icon: CurrencyLiraIcon,
        pathname: '/finans-analizleri',
        page: FinancialAnalysis,
        isDetailPage: false,
        permissions: [
            'view_revenue',
        ]
    },
    {
        label: 'Satın Alma',
        icon: ShoppingCartOutlinedIcon,
        pathname: '/satin-alma',
        page: Purchases,
        isDetailPage: false,
        permissions: [
            'view_purchase',
        ]
    },
    {
        label: 'Satın Alma',
        icon: ShoppingCartOutlinedIcon,
        pathname: '/satin-alma/:id',
        page: PurchaseDetail,
        isDetailPage: true,
        permissions: [
            'view_purchase',
        ]
    },
]

export {
    SignIn,
    Unauthorized,
    RefreshAuth,
}
