import Chip from "@mui/material/Chip";
import {statusTranslate} from "../../helpers/purchase/status";

const StatusChip = function ({status, sx = {}}) {
    const label = statusTranslate[status].chipLabel;
    const isStatusCompleted = status === statusTranslate.COMPLETED.enum;
    const isStatusDemand = status === statusTranslate.DEMAND.enum;
    const isStatusActive = status === statusTranslate.ACTIVE.enum;
    return (
        <Chip
            sx={sx}
            label={label}
            color={
                isStatusCompleted
                    ? `statusCompleted`
                    : isStatusDemand
                        ? 'success' : isStatusActive
                            ? 'primary' : 'warning'
            }
        />
    )
}

export default StatusChip;
