import {useContext, useState} from 'react';
import {Box, Card, CardContent, TextField, Stack, Avatar} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import LockIcon from '@mui/icons-material/Lock';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {AuthContext} from "../contexts/AuthContext";
import {useNavigate, useLocation} from "react-router-dom";
import {useSnackbar} from 'notistack';

const validationSchema = yup.object({
    username: yup
        .string('Kullanıcı adını giriniz')
        .required('Kullanıcı adı gerekli!'),
    password: yup
        .string('Parolanızı giriniz')
        .required('Parola gerekli!'),
});

export default function SignIn() {
    const authContext = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/is-emirleri";

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            console.log(JSON.stringify(values));
            authContext.signIn(values)
                .then(() => {
                    navigate(from, {replace: true});
                })
                .catch((err) => {
                    setLoading(false);
                    let message = 'Bir hata oldu'
                    if (err?.response?.status === 401) {
                        message = 'Kullanıcı adı veya parola hatalı!'
                    }
                    enqueueSnackbar(message, {
                        variant: "error"
                    });
                });
        },
    });

    return (
        <Box sx={theme => ({
            height: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        })}>
            <Box sx={theme => ({
                width: '200px',
                [theme.breakpoints.up('md')]: {
                    width: '30%',
                },
            })}>
                <img alt="logo" src="/logo512.png" style={{width: '100%'}}/>
            </Box>
            <Card sx={{width: '100%', maxWidth: 'sm'}}>
                <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
                        <Avatar>
                            <LockIcon/>
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            textAlign: 'center',
                            fontSize: 'h5.fontSize',
                            fontWeight: '600',
                            letterSpacing: 1
                        }}
                    >KESİS PORTAL</Box>
                    <Box sx={{textAlign: 'center', fontWeight: 'light'}}>Hoşgeldiniz. Lütfen giriş yapınız.</Box>
                    <Stack component="form" sx={{width: '100%', py: 5, px: {sm: 5}}} spacing={2}
                           onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            id="username"
                            name="username"
                            label="Kullanıcı adı"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            error={formik.touched.username && Boolean(formik.errors.username)}
                            helperText={formik.touched.username && formik.errors.username}
                        />
                        <TextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Parola"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            sx={{textTransform: 'none'}}
                        >Giriş yap</LoadingButton>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}
