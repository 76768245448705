import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {transformDateToLocalString} from "../../utils/transform";
import {useContext} from "react";
import {DetailPageContext} from "../detail-page-hoc/context";
import {userLabelFormat} from "../../helpers/user/format";

const DetailTable = function ({tableSize = 'medium'}) {
    const {state} = useContext(DetailPageContext);

    return (
        <TableContainer component={Paper}>
            <Table size={tableSize}>
                <TableBody>
                    <TableRow>
                        <TableCell>Oluşturan Kişi</TableCell>
                        <TableCell>{userLabelFormat({user: state.data.user})}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Oluşturma Tarihi</TableCell>
                        <TableCell>{transformDateToLocalString(state.data.createdAt, false)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DetailTable;
