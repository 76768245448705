import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';

const transformObjectKeysToCamelCase = (obj) => {
    if (typeof obj === 'string') return obj;
    if (Array.isArray(obj)) {
        return obj.map(item => transformObjectKeysToCamelCase(item));
    }
    return Object.entries(obj).reduce((obj, [key, value]) => {
        if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
            return {
                ...obj,
                [camelCase(key)]: transformObjectKeysToCamelCase(value)
            }
        }
        return {
            ...obj,
            [camelCase(key)]: value
        }
    }, {})
}

const transformObjectKeysToSnakeCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(item => transformObjectKeysToSnakeCase(item));
    }
    return Object.entries(obj).reduce((obj, [key, value]) => {
        if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
            return {
                ...obj,
                [camelCase(key)]: transformObjectKeysToSnakeCase(value)
            }
        }
        return {
            ...obj,
            [snakeCase(key)]: value
        }
    }, {})
}

const transformMinutesToTimeFormat = (minutes) => {
    return `${String(Math.floor(Number(minutes) / 60)).padStart(2, '0')}:${String(minutes % 60).padStart(2, '0')}`
}

const transformTimeFormatToMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    if (minutes >= 60) {
        return {error: 'Dakika bölümü en fazla 59 olabilir'}
    } else if (hours.includes('_') || minutes.includes('_')) {
        return {error: 'Saat formatını düzgün giriniz: Ör: 05:30'}
    }
    return {minutes: Number(minutes) + Number(hours) * 60};
}

const transformDateToLocalString = (datetime, onlyDate = true, includeSecond = false) => {
    const date = new Date(datetime);
    let options = {}
    if (!onlyDate) {
        options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }
        if (includeSecond) {
            options.second = 'numeric'
        }
    }
    return new Intl.DateTimeFormat('tr-TR', options).format(date);
}

const transformSortModelToQueryString = (sortModel) => {
    const queryStringArray = sortModel.map(({field, sort}) => {
        const fieldName = snakeCase(field);
        return sort === 'desc' ? `-${fieldName}` : fieldName
    });
    return `ordering=${queryStringArray.join(',')}`
}

const transformFiltersToQueryString = (filters) => {
    const queryStringArray = Object.entries(filters).map(([field, value]) => {
        const fieldName = snakeCase(field);
        if (Array.isArray(value)) {
            return value.map(value => `${fieldName}=${value}`)
        }
        return `${fieldName}=${value ? value : ''}`;
    });
    return queryStringArray.flat().join('&')
}

export {
    transformObjectKeysToCamelCase,
    transformObjectKeysToSnakeCase,
    transformDateToLocalString,
    transformSortModelToQueryString,
    transformFiltersToQueryString,
    transformMinutesToTimeFormat,
    transformTimeFormatToMinutes,
}
