import {DataGridPro, trTR} from '@mui/x-data-grid-pro';
import Box from "@mui/material/Box";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import {Popover} from "@mui/material";
import Footer from "./Footer";

function DataTable(
    {
        rows,
        columns,
        loading,
        sortModel,
        onSortModelChange,
        onFilterModelChange,
        initialState = {},
        rowCount,
        page,
        pageSize,
        onPageChange,
        onPageSizeChange,
        getRowHeight = () => null,
    }
) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('');
    const handlePopoverOpen = (event) => {
        const fieldValue = event.currentTarget.querySelector('.MuiDataGrid-cellContent')?.innerHTML || '';
        if (fieldValue.length > 10) {
            setValue(fieldValue);
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Box sx={{mt: 3, flex: '1 1 auto', width: '100%', maxHeight: '90%'}}>
            <DataGridPro
                loading={loading}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                onFilterModelChange={onFilterModelChange}
                sortingMode={"server"}
                filterMode={"server"}
                rows={rows}
                getRowHeight={getRowHeight}
                columns={columns}
                localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                pagination
                paginationMode={"server"}
                paginationModel={{
                    pageSize,
                    page,
                }}
                onPaginationModelChange={(paginationModel) => {
                    if (page !== paginationModel.page) {
                        onPageChange(paginationModel.page)
                    }
                    if (pageSize !== paginationModel.pageSize) {
                        onPageSizeChange(paginationModel.pageSize)
                    }
                }}
                rowCount={rowCount}
                initialState={initialState}
                slotProps={{
                    cell: {
                        onMouseEnter: handlePopoverOpen,
                        onMouseLeave: handlePopoverClose,
                    },
                }}
                slots={{
                    footer: Footer,
                }}
            />
            <Popover
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography variant={'caption'} sx={{m: 1}}>{value}</Typography>
            </Popover>
        </Box>
    );
}

export default DataTable;
