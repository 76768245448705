import {useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Formik} from "formik";
import * as yup from "yup";
import Stack from "@mui/material/Stack";
import PurchaseApi from "../../api/purchase";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import format from "date-fns/format";
import AutocompleteField from "../forms/AutocompleteField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import SelectField from "../forms/SelectField";
import {statusTranslate} from "../../helpers/purchase/status";


const PurchaseFormDialog = function (
    {
        title,
        open,
        handleClose,
        suppliers,
        initialValues = {
            supplier: '',
            status: statusTranslate.DEMAND.enum,
        },
    }
) {
    let navigate = useNavigate();
    const authorization = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                // actions.setSubmitting(false);
                setLoading(true);
                const payload = {...values}
                Object.entries(payload).forEach(([key, value]) => {
                    if (!value) delete payload[key]
                    if (value && key.indexOf('Date') > 0) {
                        payload[key] = format(new Date(value), 'yyyy-MM-dd');
                    }
                });
                PurchaseApi.save({authorization}, payload).then(response => {
                    setLoading(false);
                    handleClose();
                    navigate(`/satin-alma/${response.id}`);
                });
            }}
        >
            {formik => (
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2} sx={{mt: 2}}>
                            <AutocompleteField
                                name="supplier"
                                label="Tedarikçi Firma"
                                values={suppliers}
                                itemLabelField="name"
                                size="small"
                            />
                            <SelectField
                                name="status"
                                label="Durum"
                                values={[
                                    {key: statusTranslate.DEMAND.enum, value: statusTranslate.DEMAND.chipLabel},
                                    {key: statusTranslate.ACTIVE.enum, value: statusTranslate.ACTIVE.chipLabel},
                                ]}
                                nullable={false}
                                valueField="key"
                                itemLabelField="value"
                                size="small"
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loading}
                            sx={{textTransform: 'none'}}
                            onClick={() => formik.submitForm()}
                        >Kaydet</Button>
                        <Button
                            disabled={loading}
                            sx={{textTransform: 'none'}}
                            color="error"
                            onClick={handleClose}
                        >İptal</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

const validationSchema = yup.object({
    supplier: yup
        .string()
        .required('Lütfen bir tedarikçi seçin'),
    status: yup
        .string()
        .required('Lütfen bir durum seçin'),
});

export default PurchaseFormDialog;
