import Button from "@mui/material/Button";
import {useContext} from "react";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";

const RefreshSortOrderButton = function () {
    const {state, dispatch} = useContext(ListPageContext);
    return (
        <Button
            onClick={() => {
                dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
                dispatch({
                    type: listPageActionTypes.SET_SORT_MODEL, sortModel: []
                })
                dispatch({
                    type: listPageActionTypes.SET_PAGINATION,
                    pagination: {
                        ...state.pagination,
                        page: 0,
                    }
                })
            }}
        >Sıralamayı Sıfırla</Button>
    )
}

export default RefreshSortOrderButton;
