import Axios from "axios";

const initializers = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
}

function returnAxiosInstance(extraConfig) {
    return Axios.create({...initializers, ...extraConfig});
}

export function get(url, config){
    const axios = returnAxiosInstance(config);
    return axios.get(url);
}

export function post(url, requestData, config){
    const axios = returnAxiosInstance(config);
    return axios.post(url, requestData);
}

export function put(url, requestData, config){
    const axios = returnAxiosInstance(config);
    return axios.put(url, requestData);
}
