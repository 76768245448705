import {forwardRef, useContext, useState} from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import {Formik} from "formik";
import * as yup from "yup";
import SelectField from "../forms/SelectField";
import Stack from "@mui/material/Stack";
import TextField from "../forms/TextField";
import {currencies} from "../../utils/currency";
import CheckboxField from "../forms/CheckboxField";
import DatePicker from "../forms/DatePicker";
import PriceFieldFormat from "../PriceFieldFormat";
import Divider from "@mui/material/Divider";
import {
    operationsYupValidationObject,
    operationsFormikInitialValues,
    removeOperationFields
} from "../../helpers/operations";
import WorkOrderApi from "../../api/work-order";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import addWeeks from "date-fns/addWeeks";
import nextFriday from "date-fns/nextFriday";
import isFriday from "date-fns/isFriday";
import format from "date-fns/format";
import {transformTimeFormatToMinutes} from "../../utils/transform";
import TimeFieldFormat from "../TimeFieldFormat";
import AutocompleteField from "../forms/AutocompleteField";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WorkOrderFormDialog = function (
    {
        open,
        handleClose,
        products,
        customers,
        initialValues = {
            product: '',
            customer: '',
            companyProductCode: '',
            companyOrderId: '',
            quantity: '',
            createAsMultiple: false,
            offerDate: '',
            confirmationDate: '',
            deadline: '',
            customerProductCode: '',
            customerOrderId: '',
            description: '',
            notes: '',
            unitPrice: '',
            currency: 'EUR',
            dispatchType: ''
        },
    }
) {
    let navigate = useNavigate();
    const authorization = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <Formik
                initialValues={{...operationsFormikInitialValues, ...initialValues}}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    // actions.setSubmitting(false);
                    setLoading(true);
                    const productOperations = products
                        .find(product => product.id === values.product)
                        .operations
                        .filter(operation => operation.timeTrackingEnabled);
                    const {errors, operations} = productOperations.reduce((result, operation) => {
                        const {error, minutes} = transformTimeFormatToMinutes(values[operation.shortCode])
                        if (error) {
                            result.errors[operation.shortCode] = error;
                        } else {
                            result.operations.push({
                                category: operation.id,
                                estimatedOperationTime: minutes,
                            })
                        }
                        return result;
                    }, {errors: {}, operations: []});
                    if (Object.keys(errors).length > 0) {
                        actions.setErrors(errors);
                        setLoading(false);
                    } else {
                        const payload = removeOperationFields(values);
                        Object.entries(payload).forEach(([key, value]) => {
                            if (!value) delete payload[key]
                            if (value && key === 'deadline') {
                                const deadlineWeekDate = addWeeks(
                                    payload.confirmationDate
                                        ? new Date(payload.confirmationDate)
                                        : new Date(),
                                    value
                                )
                                const date = isFriday(deadlineWeekDate)
                                    ? deadlineWeekDate
                                    : nextFriday(deadlineWeekDate);
                                payload[key] = format(date, 'yyyy-MM-dd');
                            }
                            if (value && key.indexOf('Date') > 0) {
                                payload[key] = format(new Date(value), 'yyyy-MM-dd');
                            }
                        });
                        payload.operations = operations;
                        WorkOrderApi.save({authorization}, payload).then(response => {
                            setLoading(false);
                            handleClose();
                            navigate(`/is-emirleri/${response.id}`);
                        });
                    }
                }}
            >
                {formik => (
                    <>
                        <AppBar sx={{position: 'relative'}}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon/>
                                </IconButton>
                                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                    İş emri oluştur
                                </Typography>
                                <LoadingButton
                                    color={'inherit'}
                                    sx={{ml: 1, textTransform: 'none', displayPrint: 'none'}}
                                    onClick={() => formik.submitForm()}
                                    loading={loading}
                                >Kaydet</LoadingButton>
                            </Toolbar>
                        </AppBar>
                        <Grid spacing={1} container sx={{p: 3}}>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"subtitle2"}>Firma bilgileri</Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <AutocompleteField
                                        name={'customer'}
                                        label={'Müşteri'}
                                        values={customers}
                                        itemLabelField={'name'}
                                        size={'small'}
                                    />
                                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                                        <TextField
                                            name={'customerProductCode'}
                                            label={'Müşteri Ürün Kodu'}
                                            size={'small'}/>
                                        <TextField
                                            name={'customerOrderId'}
                                            label={'Müşteri Sipariş No'}
                                            size={'small'}
                                        />
                                    </Stack>
                                </Stack>
                                <Typography
                                    variant={"subtitle2"}
                                    sx={{mt: 2}}
                                >Ürün bilgileri</Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{mt: 2}}>
                                    <SelectField
                                        name={'product'}
                                        label={'Ürün / Hizmet / Satış'}
                                        values={products}
                                        itemLabelField={'name'}
                                        size={'small'}
                                        onChange={event => {
                                            if (event.target.value === 18) {
                                                formik.setFieldValue(
                                                    'companyProductCode',
                                                    'KESTAŞ YÜKSEK PERFORMANSLI BROŞLAMA YAĞI'
                                                );
                                            } else {
                                                if (
                                                    formik.values.companyProductCode ===
                                                    'KESTAŞ YÜKSEK PERFORMANSLI BROŞLAMA YAĞI'
                                                ) {
                                                    formik.setFieldValue(
                                                        'companyProductCode',
                                                        ''
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                                        <TextField
                                            name={'companyProductCode'}
                                            label={'Kestaş Ürün Kodu'}
                                            size={'small'}
                                        />
                                        <TextField
                                            name={'companyOrderId'}
                                            label={'Kestaş Sipariş No'}
                                            size={'small'}
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                                        <DatePicker
                                            label={"Teklif Tarihi"}
                                            name={'offerDate'}
                                            size={'small'}
                                        />
                                        <DatePicker
                                            label={"Teklif Onay Tarihi"}
                                            name={'confirmationDate'}
                                            size={'small'}
                                        />
                                    </Stack>
                                    <TextField
                                        name={'deadline'}
                                        label={'Termin Süresi (Hafta)'}
                                        inputMode={'numeric'}
                                        size={'small'}
                                    />
                                    <Stack direction={"row"} spacing={1} alignItems={'center'}>
                                        <TextField
                                            name={'quantity'}
                                            label={'Adet veya Kg'}
                                            inputMode={'numeric'}
                                            size={'small'}
                                        />
                                        <CheckboxField
                                            name={'createAsMultiple'}
                                            label={'Çoklu oluştur'}
                                            size={'small'}
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={1}>
                                        <TextField
                                            name={'unitPrice'}
                                            label={'Birim Fiyat'}
                                            inputMode={'decimal'}
                                            InputProps={{
                                                inputComponent: PriceFieldFormat
                                            }}
                                            size={'small'}
                                        />
                                        <SelectField
                                            name={'currency'}
                                            label={'Para Birimi'}
                                            values={currencies}
                                            nullable={false}
                                            valueField={'key'}
                                            size={'small'}
                                        />
                                    </Stack>
                                    <TextField
                                        name={'description'}
                                        label={'Ürün Ebat/Cins Açıklaması'}
                                        multiline
                                        size={'small'}
                                    />
                                    <TextField
                                        name={'notes'}
                                        label={'Notlar'}
                                        multiline
                                        size={'small'}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"subtitle2"}>Operasyon bilgileri</Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{mt: 2}}>
                                    {
                                        !formik.values.product
                                            ? (
                                                <Typography variant={"body2"}>Ürün seçiniz</Typography>
                                            )
                                            : (
                                                products
                                                    .find(product => product.id === formik.values.product)
                                                    .operations
                                                    .filter(operation => operation.timeTrackingEnabled)
                                                    .map(operation => {
                                                        return (
                                                            <TextField
                                                                key={operation.shortCode}
                                                                name={operation.shortCode}
                                                                label={`${operation.name} (${operation.shortCode})`}
                                                                size={'small'}
                                                                InputProps={{
                                                                    inputComponent: TimeFieldFormat
                                                                }}
                                                            />
                                                        )
                                                    })
                                            )
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}

const validationSchema = yup.object({
    product: yup
        .string()
        .required('Lütfen bir ürün/hizmet/satış tipi seçin'),
    customer: yup
        .string()
        .required('Lütfen bir müşteri seçin'),
    companyProductCode: yup
        .string(),
    // .required('Kestaş ürün kodunu giriniz'),
    companyOrderId: yup
        .string()
        // .required('Lütfen bir müşteri seçin')
        .matches(/(^[0-9]{3}\/[0-9]{2}$)/, {excludeEmptyString: true, message: 'Lütfen formata uyunuz'}),
    offerDate: yup
        .date(),
    // .required('Teklif tarihini seçin'),
    confirmationDate: yup
        .date(),
    // .required('Teklif tarihini seçin'),
    customerProductCode: yup.string(),
    customerOrderId: yup.string(),
    description: yup
        .string(),
    // .required('Açıklama giriniz'),
    quantity: yup
        .number()
        .required('Miktar giriniz')
        .moreThan(0, 'Miktar sıfırdan büyük olmalı'),
    createAsMultiple: yup
        .boolean()
        .when('quantity', (quantity, schema) => {
            return quantity && Number(quantity) > 1
                ? schema.oneOf([true, false])
                : schema.oneOf([false], 'Adet birden büyük olmalı');
        }),
    deadline: yup
        .number()
        //.required('Hafta sayısı giriniz')
        .moreThan(0, 'Hafta sıfırdan büyük olmalı'),
    notes: yup
        .string(),
    unitPrice: yup
        .number()
        .min(0, 'Birim fiyat negatif olamaz'),
    currency: yup
        .string()
        .oneOf(currencies.map(currency => currency.value)),
    ...operationsYupValidationObject,
});

export default WorkOrderFormDialog;
