import {useCallback, useContext} from "react";
import BaseDataTable from "../datatable";
import PurchaseApi from "../../api/purchase";
import {transformDateToLocalString} from "../../utils/transform";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {ListPageContext} from "../list-page-hoc/context";
import {listPageActionTypes} from "../list-page-hoc/constants";
import CopyToClipboardButton from "../CopyToClipboardButton";
import {useListApi} from "../list-page-hoc/hooks";
import StatusChip from "./StatusChip";
import {userLabelFormat} from "../../helpers/user/format";

function DataTable() {
    const {state, dispatch} = useContext(ListPageContext);
    let navigate = useNavigate();
    const {data: purchases} = useListApi(PurchaseApi)

    const handleSortModelChange = sortModel => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({type: listPageActionTypes.SET_SORT_MODEL, sortModel})
    };

    const handleFilterModelChange = useCallback(filterModel => {
        console.log(filterModel);
    }, []);

    const onPageChange = (page) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                page: page,
            }
        })
    }

    const onPageSizeChange = (pageSize) => {
        dispatch({type: listPageActionTypes.SET_LOADING, loading: true});
        dispatch({
            type: listPageActionTypes.SET_PAGINATION,
            pagination: {
                ...state.pagination,
                pageSize,
            }
        })
    }

    return (
        <BaseDataTable
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            sortModel={state.sortModel}
            loading={state.loading}
            rowCount={purchases.count}
            page={state.pagination.page}
            pageSize={state.pagination.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={purchases.results}
            columns={[
                {
                    field: 'companyPurchaseNumber',
                    headerName: 'Satın Alma No',
                    sortable: false,
                    filterable: false,
                    width: 140,
                    renderCell: (params) => {
                        return (
                            <Box>
                                <Link
                                    component={'button'}
                                    variant={'body2'}
                                    onClick={() => {
                                        navigate(`/satin-alma/${params.id}`, {replace: false})
                                    }}
                                >{params.value}</Link>
                                <CopyToClipboardButton
                                    sx={{
                                        px: 1,
                                        opacity: 0.1,
                                        transition: 'opacity ease-out 300ms',
                                        '&:hover': {
                                            opacity: 1
                                        }
                                    }}
                                    copyText={params.value || params.row.customerOrderId}
                                    message={'Satın alma numarası kopyalandı'}
                                />
                            </Box>
                        )
                    }
                },
                {
                    field: 'supplier',
                    filterable: false,
                    headerName: 'Tedarikçi Firma',
                    width: 300,
                    flex: 1,
                },
                {
                    field: 'user',
                    filterable: false,
                    headerName: 'Oluşturan',
                    sortable: false,
                    width: 150,
                    valueFormatter: ({value: user}) => {
                        return userLabelFormat({user});
                    },
                },
                {
                    field: 'status',
                    headerName: 'Durum',
                    filterable: false,
                    sortable: false,
                    width: 120,
                    renderCell: ({value}) => {
                        return (
                            <StatusChip status={value}/>
                        );
                    }
                },
                {
                    field: 'createdAt',
                    filterable: false,
                    headerName: 'Oluşturma Tarihi',
                    width: 135,
                    type: 'date',
                    valueFormatter: (params) => {
                        return transformDateToLocalString(params.value);
                    }
                },
            ]}
        />
    )
}

export default DataTable;
