import {createContext, useReducer, useEffect} from 'react';
import {listPageReducer} from "./reducer";

const ListPageContext = createContext(null);

function ListPageProvider(
    {
        filters = {},
        // ex: [{field: 'createdAt', sort: 'desc'}]
        sortModel = [],
        pagination = {page: 0, pageSize: 25},
        searchText = '',
        inputValues = {},
        children,
        storageKey = 'list-page',
        initialData,
        dataCallback,
    }
) {
    const [state, dispatch] = useReducer(listPageReducer, {
        filters,
        sortModel,
        pagination,
        searchText,
        inputValues,
        version: 1,
        loading: true,
        data: initialData,
    }, params => {
        const storedState = JSON.parse(localStorage.getItem(storageKey) || '{}');
        return {...params, ...storedState, filters: {...params.filters, ...storedState.filters}};
    })

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify({
            filters: state.filters,
            sortModel: state.sortModel,
            pagination: state.pagination,
            searchText: state.searchText,
            inputValues: state.inputValues,
        }));
    }, [state, storageKey])

    useEffect(() => {
        if (dataCallback) {
            dataCallback().then(data => dispatch({type: 'SET_DATA', data}))
        }
    }, [dataCallback]);

    return <ListPageContext.Provider value={{state, dispatch}}>{children}</ListPageContext.Provider>;
}

export {ListPageContext};
export default ListPageProvider;
