import {useState} from "react";
import Button from "@mui/material/Button";
import {hasPermission} from "../../utils/permissions";
import PurchaseItemFormDialog from "./PurchaseItemFormDialog";

const CreatePurchaseItemButton = function ({sx = {}}) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button
                variant={'outlined'}
                sx={{textTransform: 'none', displayPrint: 'none', ...sx}}
                onClick={() => setOpen(true)}
            >Ürün Ekle</Button>
            <PurchaseItemFormDialog
                title="Ürün oluştur"
                open={open}
                handleClose={() => setOpen(false)}
            />
        </>
    )
}

export default hasPermission(CreatePurchaseItemButton, {permissions: ['add_purchaseitem']});
